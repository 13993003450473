<script lang="ts">
	import './styles.css'
	import { refreshSessionCookie } from '$lib/firebase-client/auth'
	import { onMount } from 'svelte'
	import { Toaster } from '$lib/components/ui/sonner'
	import { user } from '$lib/stores/userStore'
	import { parsedToken } from '$lib/stores/parsedTokenStore'
	import { getClientAuth } from '$lib/firebase-client/app'
	import { onAuthStateChanged } from 'firebase/auth'
	let isFirstVisit = true

	onMount(() => {
		const auth = getClientAuth()

		return onAuthStateChanged(auth, (authUser) => {
			console.log('authUser', authUser)
			user.set(authUser)
			authUser
				?.getIdTokenResult(true)
				.then((token) => {
					parsedToken.set(token.claims)
				})
				.catch((e) => {
					console.log('Failed to get id token result', e)
				})
			if (isFirstVisit) {
				if (authUser) {
					return refreshSessionCookie(authUser, true).catch((e) =>
						console.log('Failed to refresh session cookie', e)
					)
				}
			}
			isFirstVisit = false
		})
	})
</script>

<Toaster />

<slot />
